import React from 'react'
import { useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';


function CoverLetter() {
    const state = useLocation().state;
    console.log(state)
    return (
        <div className="h-[100vh]">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">

                    {state.length > 0 ? <p className='font-medium text-[1.5rem]'>{state}</p> : <p className='font-bold text-gray-500 text-[2rem]'>This appplicant did not provide a cover letter</p>}
                </div>
            </div>
        </div>
    )
}

export default CoverLetter