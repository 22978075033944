import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";

function Emails() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            "https://atafrica-api.vercel.app/api/newsletter/all"
          );
          setPosts(res.data);
          console.log(res.data)
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchData();
    }, []);
  
    return (
      <div className="h-[100vh]">
        <div className="flex h-[100%]">
          <Sidebar />
          <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
            <h1 className="mb-5 text-4xl font-bold text-[#359602]">
              All Emails
            </h1>
            <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
              {posts.length > 0 ? (
                posts?.map((post) => (
                  <div
                    key={post._id}
                    className="flex w-[90%] md:w-[48.3%] shadow-xl flex-col border border-[#FFA500] px-3 py-3"
                  >
                    <h1 className="mb-2 text-xl font-semibold">
                      Email: {post.email}
                    </h1>
                  </div>
                ))
              ) : (
                <p className="text-center">No newsletter emails yet...</p>
              )}
            </div>
            {/* <Link to="/add-job">
              <button className="fixed bottom-10 right-10 bg-[#359602] text-white text-2xl px-2 py-2 rounded-xl">
                Add Job
              </button>
            </Link> */}
            <Link to="/login">
              <p className="text-black text-2xl md:hidden top-16 right-2 fixed px-2 text-center py-2 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
                Log out
              </p>
            </Link>
          </div>
        </div>
      </div>
    );
}

export default Emails