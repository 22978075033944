import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div className="hidden w-[15%] h-[100%] bg-[#359602] md:flex flex-col justify-between  pt-10 px-5">
      <div className="flex flex-col gap-10 w-[100%] cursor-pointer">
        <Link to="/home">
          <p className="text-white md:text-xl px-1.5 py-2 bg-black border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
            All Jobs
          </p>
        </Link>
        <Link to="/emails">
          <p className="text-white md:text-xl px-1.5 py-2 bg-[black] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
            Newsletter Emails
          </p>
        </Link>
        <Link to="/discussions">
          <p className="text-white md:text-xl px-1.5 py-2 bg-[black] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
            Discussions
          </p>
        </Link>
        <Link to="/applications">
          <p className="text-white md:text-xl px-1.5 py-2 bg-[black] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
            Applications
          </p>
        </Link>
      </div>
      <Link to="/login" className="mb-10">
        <p className="text-black md:text-xl px-1.5 text-center py-2 bg-[#FFA500] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
          Log out
        </p>
      </Link>
    </div>
  );
}

export default Sidebar;
