import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";

function Applications() {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://atafrica-api.vercel.app/api/applications/all"
        );
        setApplications(res.data);
        console.log(res.data)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            All Applications
          </h1>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
            {applications.length > 0 ? (
              applications?.map((post) => (
                <div
                  key={post._id}
                  className="flex w-[90%] md:w-[48.3%] shadow-xl flex-col border border-[#FFA500] px-3 py-3"
                >
                  <h1 className="mb-2 text-xl font-semibold">
                    Job Title: {post.jobTitle}
                  </h1>
                  <h1>Applicant's First Name: {post.firstName}</h1>
                  <h1>Applicant's Last Name: {post.lastName}</h1>
                  <h1>Applicant's Email: {post.email}</h1>
                  <h1>Applicant's Phone Number: {post.phone}</h1>
                  <div className="flex justify-between">
                    <Link to={`${post.resume}`} target="_blank">
                      <button className="bg-[#359602] text-white text-2xl rounded-xl mt-5 px-2 py-1">
                        Get Resume
                      </button>
                    </Link>
                    {post.coverLetterType === "upload" ?
                      <Link to={`${post.coverLetter}`} target="_blank">
                        <button className="bg-[#359602] text-white text-xl rounded-xl mt-5 px-2 py-1">
                          Get Cover Letter
                        </button>
                      </Link> : <Link to={`/cover_letter`} state={post.coverLetter}><button className="bg-[#359602] text-white text-xl rounded-xl mt-5 px-2 py-1">
                        View Cover Letter
                      </button></Link>}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No applications yet...</p>
            )}
          </div>
          {/* <Link to="/add-job">
              <button className="fixed bottom-10 right-10 bg-[#359602] text-white text-2xl px-2 py-2 rounded-xl">
                Add Job
              </button>
            </Link> */}
          <Link to="/login">
            <p className="text-black text-2xl md:hidden top-16 right-2 fixed px-2 text-center py-2 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Applications