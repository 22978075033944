import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";

function AddJob() {
    const state = useLocation().state;
    const [jobInfo, setJobInfo] = useState({
        title: state?.title || "",
        category: state?.category || "",
        location: state?.location || "",
        type: state?.type || "",
        company: state?.company || "",
        looking_desc: state?.lookingDesc || "",
        experience_desc: state?.experienceDesc || "",
        benefits_desc: state?.benefitsDesc || "",
        looking_points: state?.lookingPoints || [],
        experience_points: state?.experiencePoints || [],
        benefits_points: state?.benefitsPoints || [],
        
    });
    const navigate = useNavigate();
    console.log(jobInfo)



    const handleSubmit = async (e) => {
        e.preventDefault();
        const job = jobInfo;

        try {
            state
                ? await axios.put(
                    `https://atafrica-api.vercel.app/api/jobs/update/${state._id}`,
                    job
                )
                : await axios.post(
                    "https://atafrica-api.vercel.app/api/jobs/add",
                    job
                );

            setJobInfo({
                title: "",
                category: "",
                location: "",
                type: "",
                company: "",
                looking_desc: "",
                experience_desc: "",
                benefits_desc: "",
                looking_points: [],
                experience_points: [],
                benefits_points: [],
            });
            alert("Your information has been submitted Successfully...");
            navigate("/home");
        } catch (error) {
            console.log(error);
            alert(
                "Please ensure that you have fill out the form below..."
            );
            window.location.reload();
        }
    };

    return (
        <div className="h-[100vh]">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className="w-[100%] md:w-[85%] h-[100%] flex flex-col items-center justify-center overflow-y-scroll md:pt-[45rem] pt-[80rem] pb-5">
                    <form
                        onSubmit={(e) => handleSubmit(e)}
                        className="w-[80%] flex flex-col gap-9 items-center"
                    >
                        <h1 className="font-open font-bold text-xl mb-9 text-center">
                            Input Job information
                        </h1>
                        <div className="flex md:flex-row flex-col items-center gap-10">
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Title
                                </label>
                                <input
                                    type="text"
                                    placeholder="Eg. React Native Developer"
                                    value={jobInfo.title}
                                    onChange={(e) =>
                                        setJobInfo({
                                            ...jobInfo,
                                            title: e.target.value,
                                        })
                                    }
                                    className="border outline-none bg-[#E9E9E9] font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Category
                                </label>
                                <input
                                    type="text"
                                    placeholder="Eg. Engineering"
                                    value={jobInfo.category}
                                    onChange={(e) =>
                                        setJobInfo({
                                            ...jobInfo,
                                            category: e.target.value,
                                        })
                                    }
                                    className="border outline-none bg-[#E9E9E9] font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]"
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-10 md:flex-row flex-col">
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Location
                                </label>
                                <input
                                    type="text"
                                    placeholder="Eg. Lagos, Nigeria"
                                    value={jobInfo.location}
                                    onChange={(e) =>
                                        setJobInfo({
                                            ...jobInfo,
                                            location: e.target.value,
                                        })
                                    }
                                    className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Type
                                </label>
                                <input
                                    type="text"
                                    placeholder="Eg. hybrid"
                                    value={jobInfo.type}
                                    onChange={(e) =>
                                        setJobInfo({
                                            ...jobInfo,
                                            type: e.target.value,
                                        })
                                    }
                                    className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]"
                                />
                            </div>
                        </div>
                        <div className="flex items-start gap-10 md:flex-row flex-col">
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Company
                                </label>
                                <input
                                    type="text"
                                    placeholder="Eg. FT9ja"
                                    value={jobInfo.company}
                                    onChange={(e) =>
                                        setJobInfo({
                                            ...jobInfo,
                                            company: e.target.value,
                                        })
                                    }
                                    className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Main description
                                </label>
                                <textarea value={jobInfo.looking_desc} onChange={e => {
                                    setJobInfo({
                                        ...jobInfo,
                                        looking_desc: e.target.value
                                    })
                                }} className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                        </div>
                        <div className="flex items-start gap-10 md:flex-row flex-col">
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Main points (Enter each point on a new line.)
                                </label>
                                <textarea value={Object.values(jobInfo.looking_points).join("\n")} onChange={(e) => {
                                    console.log(
                                        e.target.value.split("\n")
                                    );
                                    setJobInfo(
                                        {
                                            ...jobInfo,
                                            looking_points: e.target.value.split("\n")
                                        }
                                    );
                                }} F className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Experience description
                                </label>
                                <textarea value={jobInfo.experience_desc} onChange={e => {
                                    setJobInfo({
                                        ...jobInfo,
                                        experience_desc: e.target.value
                                    })
                                }} className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                        </div>
                        <div className="flex items-start gap-10 md:flex-row flex-col">
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-[0.9rem] mb-4"
                                >
                                    Experience points (Enter each point on a new line.)
                                </label>
                                <textarea
                                    value={Object.values(jobInfo.experience_points).join("\n")}
                                    onChange={(e) => {
                                        console.log(
                                            e.target.value.split("\n")
                                        );
                                        setJobInfo(
                                            {
                                                ...jobInfo,
                                                experience_points: e.target.value.split("\n")
                                            }
                                        );
                                    }} className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-base mb-4"
                                >
                                    Benefits description
                                </label>
                                <textarea value={jobInfo.benefits_desc} onChange={e => {
                                    setJobInfo({
                                        ...jobInfo,
                                        benefits_desc: e.target.value
                                    })
                                }} className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                        </div>

                        <div className="flex items-center gap-10 md:flex-row flex-col ">
                            <div className="flex flex-col">
                                <label
                                    htmlFor=""
                                    className="font-plus font-medium text-[0.9rem] mb-4"
                                >
                                    Benefits points (Enter each point on a new line.)
                                </label>
                                <textarea value={Object.values(jobInfo.benefits_points).join("\n")} onChange={(e) => {
                                    console.log(
                                        e.target.value.split("\n")
                                    );
                                    setJobInfo(
                                        {
                                            ...jobInfo,
                                            benefits_points: e.target.value.split("\n")
                                        }
                                    );
                                }} className="border outline-none bg-[#E9E9E9] py-3 w-[303px] font-plus font-bold text-lg px-3 rounded-[10px]" name="" id="" cols="30" rows="5"></textarea>
                            </div>
                            <div>
                                <button className="border outline-none bg-[#359602] text-white font-plus font-bold text-lg py-3 w-[303px] px-3 rounded-[10px]">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                    <Link to="/home">
                        <button className="bg-[#359602] text-white text-2xl px-2 py-2 mb-3 rounded-[10px] mt-10">
                            Back to Jobs
                        </button>
                    </Link>
                </div>

            </div>
        </div>
    );
}

export default AddJob;
