import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AddJob from "./pages/AddJob";
import Emails from "./pages/Emails";
import Discussions from "./pages/Discussions";
import Applications from "./pages/Applications";
import Single from "./pages/Single";
import CoverLetter from "./pages/CoverLetter";
// import Register from "./pages/Register";
// import AddParticipants from "./pages/AddParticipants";
// import Single from "./pages/Single";

const user = localStorage.getItem("ft9jaPromo");
console.log(user);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/home/:id",
    element: <Single />,
  },
  {
    path: "/add-job",
    element: <AddJob />,
  },
  {
    path: "/emails",
    element: <Emails />,
  },
  {
    path: "/discussions",
    element: <Discussions />,
  },
  {
    path: "/applications",
    element: <Applications />,
  },
  {
    path: "/cover_letter",
    element: <CoverLetter />,
  },

]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
