import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";

function Single() {
    const [post, setPost] = useState({});


    const location = useLocation();
    const pathId = location.pathname.split("/")[2];

    const navigate = useNavigate();
    console.log(window.location.origin)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `https://atafrica-api.vercel.app/api/jobs/single/${pathId}`
                );
                setPost(res.data);
                console.log(res)
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [pathId]);

    const handleDelete = async () => {
        try {
            await axios.delete(
                `https://atafrica-api.vercel.app/api/jobs/delete/${pathId}`
            );
            navigate("/home");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="h-[100vh] md:pl-0 pl-5">
            <div className="flex h-[100%]">
                <Sidebar />
                <div className=" relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center pt-5">
                    <div className="w-[100%] md:w-[70%] flex flex-col items-start gap-5">

                        <h1 className="text-lg font-medium">
                            Title:
                            <span className="font-normal"> {post?.title}</span>
                        </h1>
                        <h1 className="text-lg font-medium">
                            Category: <span className="font-normal">{post?.category}</span>
                        </h1>
                        <h1 className="text-lg font-medium">
                            Location: <span className="font-normal">{post?.location}</span>
                        </h1>
                        <h1 className="text-lg font-medium">
                            Type:{" "}
                            <span className="font-normal">{post?.type}</span>
                        </h1>
                        <h1 className="text-lg font-medium">
                            Company:{" "}
                            <span className="font-normal">{post?.company}</span>
                        </h1>
                        <div>
                            <h1 className="text-lg font-medium">
                                Main Description:{" "}
                                <span className="font-normal">{post?.lookingDesc}</span>
                            </h1>
                            <div className="flex gap-8 mt-5">
                                <p className="text-lg font-medium">Main points: </p>
                                {post.title && <ul>
                                    {post?.lookingPoints.map(point => (
                                        <li className="list-disc" key={point}>{point}</li>
                                    ))}
                                </ul>}
                            </div>
                        </div>
                        <div>
                            <h1 className="text-lg font-medium">
                                Experience Description:{" "}
                                <span className="font-normal">{post?.experienceDesc}</span>
                            </h1>
                            <div className="flex gap-8 mt-5">
                                <p className="text-lg font-medium">Experience points: </p>
                                {post.title && <ul>
                                    {post?.experiencePoints.map(point => (
                                        <li className="list-disc" key={point}>{point}</li>
                                    ))}
                                </ul>}
                            </div>
                        </div>
                        <div>
                            <h1 className="text-lg font-medium">
                                Benefits Description:{" "}
                                <span className="font-normal">{post?.benefitsDesc}</span>
                            </h1>
                            <div className="flex gap-8 mt-5">
                                <p className="text-lg font-medium">Benefits points: </p>
                                {post.title && <ul>
                                    {post?.benefitsPoints.map(point => (
                                        <li className="list-disc" key={point}>{point}</li>
                                    ))}
                                </ul>}
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-5">
                        <Link to={`${window.location.origin}/add-job`} state={post}>
                            <button className="bg-[#359602] text-white text-2xl px-2 py-2 my-3 rounded-[10px]">
                                Edit
                            </button>
                        </Link>
                        <button
                            onClick={handleDelete}
                            className="bg-black text-white text-2xl px-2 py-2 my-3 rounded-[10px]"
                        >
                            Delete
                        </button>
                    </div>


                    <Link to="/add-job" state={post}>
                        <button className=" md:hidden fixed bottom-16 left-2 bg-[#359602] text-white text-2xl px-2 py-2 rounded-xl">
                            Add Job
                        </button>
                    </Link>
                    <Link to="/login">
                        <p className="text-black text-2xl md:hidden top-16 right-2 fixed px-2 text-center py-2 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
                            Log out
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Single;
