import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Modal from 'react-modal';

function Home() {
  const [posts, setPosts] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://atafrica-api.vercel.app/api/jobs/all"
        );
        setPosts(res.data);
        console.log(res.data)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="h-[100vh]">
      <div className="flex h-[100%]">
        <Sidebar />
        <div className="relative overflow-y-scroll w-[100%] md:w-[85%] flex flex-col items-center py-5">
          <h1 className="mb-5 text-4xl font-bold text-[#359602]">
            All Jobs
          </h1>
          <div className="w-[100%] md:w-[70%] flex items-center flex-col md:flex-row md:flex-wrap gap-5">
            {posts.length > 0 ? (
              posts?.map((post) => (
                <div
                  key={post._id}
                  className="flex w-[90%] md:w-[48.3%] shadow-xl flex-col border border-[#FFA500] px-3 py-3"
                >
                  <h1 className="mb-2 text-xl font-semibold">
                    title: {post.title}
                  </h1>
                  <h1>company: {post.company}</h1>
                  <h1>type: {post.type}</h1>
                  <Link to={`/home/${post._id}`}>
                    <button className="bg-[#359602] text-white text-2xl rounded-xl mt-5 px-2 py-1">
                      View
                    </button>
                  </Link>
                </div>
              ))
            ) : (
              <p className="text-center">No Job listings yet...</p>
            )}
          </div>
          <Link to="/add-job">
            <button className="fixed bottom-10 right-10 bg-[#359602] text-white text-2xl px-2 py-2 rounded-xl">
              Add Job
            </button>
          </Link>

          <button onClick={openModal} className="fixed bottom-10 left-10 bg-[#FFA500] text-black text-2xl px-2 py-2 rounded-xl">
            Menu
          </button>

          <Link to="/login">
            <p className="text-black text-2xl md:hidden top-16 right-2 fixed px-2 text-center py-2 bg-[#FFA500] border border-black rounded-xl cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            width: "100%",
            height: "fit-content"
          }
        }}
        contentLabel="Example Modal"
      >
        <div>
          <div className="flex flex-col gap-2 w-[100%] cursor-pointer">
            <Link to="/home">
              <p className="text-white md:text-xl px-1.5 py-2 bg-black border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
                All Jobs
              </p>
            </Link>
            <Link to="/emails">
              <p className="text-white md:text-xl px-1.5 py-2 bg-[black] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
                Newsletter Emails
              </p>
            </Link>
            <Link to="/discussions">
              <p className="text-white md:text-xl px-1.5 py-2 bg-[black] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
                Discussions
              </p>
            </Link>
            <Link to="/applications">
              <p className="text-white md:text-xl px-1.5 py-2 bg-[black] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
                Applications
              </p>
            </Link>
          </div>
          <Link to="/login">
            <p className="text-black md:text-xl mt-2 px-1.5 text-center py-2 bg-[#FFA500] border border-[#1f2937] hover:border-[#FFA500] rounded-[10px] cursor-pointer">
              Log out
            </p>
          </Link>
        </div>
      </Modal>
    </div>
  );
}

export default Home;
